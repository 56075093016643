export default {
    exampleSection: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        alignItems: 'center'
    },
    addButton: {
        fontSize: '2em',
        padding: '10px 20px'
    }
}
